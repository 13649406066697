/*GLOBAL*/

body {
  background-color: black;
  background-size: cover;
}

h1,h2,h3,h4,p{
  font-family: 'VT323', monospace;
  margin: initial;
}

.btn {
  background-color: #1982C4;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.btn:hover {
  background-color: #5AB2EA;
}

.btn:active {
  background-color: #0B3753;
}

.btn-spell {
  height: 50px;
}

.btn-spell img {
  height: 32px;
  width: auto;
}

.btn-spell:disabled {
  background-color: black;
  border-color: gray;
  cursor: default;
}

.flex-center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 8px;
}

.flex-center-column {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.p-8{
  padding: 8px;
}

.p-4{
  padding: 8px;
}

.m-4{
  margin: 8px;
}

/* CHOIX PERSO */

.main-block {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
  gap: 24px;
}

.input-form {
  color: rgb(36, 35, 42);
  font-size: 16px;
  line-height: 20px;
  min-height: 28px;
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid transparent;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
  background: rgb(251, 251, 251);
  transition: all 0.1s ease 0s;
}

.input-form:focus{
  border: 2px solid rgb(124, 138, 255);
}

.perso-select__card {
  margin: 20px;
  background-repeat: no-repeat;
  border: 5px solid #0B3753;
  border-radius: 10px;
  transition: all 0.6s;
  cursor: pointer;
}

.perso-select__card:hover {
  transform: scale(1.2);
}

.perso-select__card::after {
  content: " ";    
  background-size: contain;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
}

.perso-select__card.active {
  background-color: #0B3753;
}

/*GAME*/

.game {
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 2s;
}

#game-bloc {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  min-width: 500px;
  display: flex;
  justify-content: space-between;
}

.potion{
  background-image: url("img/potion.gif");
  background-size: cover;
  opacity: 0.8;
  border-radius: 50%;
}

.potion img{
  animation: hue .5s infinite linear;
}

@keyframes hue {
  from {
    filter: hue-rotate(0deg);
  }

  to {
    filter: hue-rotate(-0.25turn);
  }
}

#character-bloc,
#monster-bloc {
  transition: opacity 0.6s all;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 48px;
  gap: 8px;
}

.character-infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 16px;
  background-color: black;
  opacity: 0.8;
  padding: 16px;
  border-radius: 16px;
  color: white;
}

.xp{
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: blue;
  color: white;
}

.energy-good{
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #73d13d;
  color: white;
  font-size: 12px;
}

.energy-bad{
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #ff4d4f;
  color: white;
  font-size: 12px;
}

#character-hp__container,
#monster-hp__container {
  content: " ";
  width: 150px;
  height: 20px;
  border-radius: 8px;
  background-color: #820014;
}

#character-hp,
#monster-hp {
  content: " ";
  width: 100%;
  height: 100%;
  background-color: #ff4d4f;
  border-radius: 8px;
  transition: all 2s;
}

#character-energy__container {
  content: " ";
  width: 150px;
  height: 20px;
  background-color: #003a8c;
  border-radius: 8px;
}

#character-energy {
  content: " ";
  width: 100%;
  height: 100%;
  background-color: #40a9ff;
  border-radius: 8px;
  transition: all 2s;
}

.animation {
  height: 300px;
  width: 300px;
}

.animation-small {
  height: 200px;
  width: 200px;
}

.reverse {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* ACTION BLOC */

#infos-bloc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#actions-bloc {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;
  display: flex;
}

#spell-bloc,
#counter-bloc,
#log-bloc {
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
  margin: 10px;
  height: 40px;
}

#spell-bloc{
  flex: 2 1 auto;
  gap: 8px;
}

#log-bloc{
  flex: 2 1 auto;
}

#counter-bloc{
  flex: 1 1 auto;
}
